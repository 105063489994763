import { template as template_b01093b48bc24b4f84e75cde443136a6 } from "@ember/template-compiler";
const FKLabel = template_b01093b48bc24b4f84e75cde443136a6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
