import { template as template_2ff2d22c3b934e1db536c3db29e7d4ae } from "@ember/template-compiler";
const SidebarSectionMessage = template_2ff2d22c3b934e1db536c3db29e7d4ae(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
