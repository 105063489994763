import { template as template_4bdc137b40194406b603b6e16d77d107 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4bdc137b40194406b603b6e16d77d107(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
