import { template as template_6998450c80c04365bb74c3234940e83c } from "@ember/template-compiler";
const WelcomeHeader = template_6998450c80c04365bb74c3234940e83c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
