import { template as template_bdeda19e9ef04a2eb61126180be1f2cf } from "@ember/template-compiler";
const FKText = template_bdeda19e9ef04a2eb61126180be1f2cf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
